import React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FormInput from "../components/form_input"
import { event as sendEvent } from "../util"

class RegisterPage extends React.Component {
  hasInput = false
  constructor(props) {
    super(props)
    this.state = {
      connectError: "",
      success: false,
      terms: false,
      username: "",
      email: "",
      password: "",
      company: "",
      usernameRules: [
        v => !!v || "Username is required",
        v => v.indexOf(" ") < 0 || "No whitespace in username",
        v =>
          (v && v.length <= 50) || "Username must be less than 50 characters",
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v =>
          (v && v.length >= 12) || "Password must have at least 12 characters",
      ],
      emailRules: [
        v => !!v || "Company E-mail is required",
        v => /.+@.+\..+/.test(v) || "Company E-mail must be valid",
        v => !v.includes("qq") || "Company email please",
      ],
      companyRules: [
        v => !!v || "Company name is required",
        v => (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      errors: {
        username: [],
        password: [],
        email: [],
        company: [],
        terms: [],
      },
    }
    this.hasInput = false
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleInputChange(e, ers) {
    if (!this.hasInput) {
      sendEvent("RegisterFormEdit")()
    }
    this.hasInput = true
    const target = e.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
    this.setState(prevState => {
      let errors = { ...prevState.errors }
      errors[name] =
        ers ||
        (value === false && name == "terms"
          ? ["You must accept the terms & conditions"]
          : [])
      return { errors }
    })
  }
  handleSubmit(e) {
    // check accepted terms of service
    if (!this.state.terms) {
      this.setState(prevState => {
        let errors = { ...prevState.errors }
        errors.terms = ["You must accept the terms & conditions"]
        return { errors, connectError: "" }
      })
      return
    }
    // don't send if we have errors
    for (let errorName in this.state.errors) {
      if (this.state.errors[errorName].length > 0) {
        return
      }
    }
    // TODO?: invitation code
    let http = new XMLHttpRequest()
    // TODO: add this to config
    const url = "https://api.cargo-planner.com/api/2/signup/"
    // let url = 'http://localhost/api/2/signup/'
    http.open("POST", url, true)
    http.setRequestHeader("Content-type", "application/json")
    http.send(JSON.stringify(this.state))
    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        switch (http.status) {
          case 201:
            // TODO: what to do on success
            navigate("/success/")
            break

          case 400:
            let response = JSON.parse(http.response)
            if (response) {
              this.setState({
                errors: response,
                connectError: "",
              })
            }
            break

          default:
            this.setState({
              connectError:
                "We are having some connection troubles at the moment. Check your internet connection or try again shortly",
            })
            break
        }
      }
    }
  }
  render() {
    const companyEmailWarning = v =>
      !/.+@(gmail)|(yahoo)|(icloud)|(live)|(aol)|(hotmail)|(yandex)|(outlook)\..+/.test(
        v
      ) || "A company email address is preferred, if possible"

    return (
      <Layout>
        <SEO title="Sign up" />
        <div className="container px-12 py-12 mx-auto flex flex-wrap flex-col lg:flex-row">
          <div className="flex flex-col w-full lg:w-2/5 items-start text-center md:text-left pl-5">
            <h1 className="my-4 text-4xl md:text-4xl  font-black leading-tight">
              Start your 10-day unlimited trial today
            </h1>
            <p className="leading-normal text-2xl mb-8">
              Get started creating loadplans in a few minutes. All modules
              included. No credit card required.
            </p>
          </div>

          <div className="w-full max-w-sm mx-auto lg:w-3/5">
            <form
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              onSubmit={this.handleSubmit}
            >
              <h2 className="text-xl font-black mb-4">Enter your details</h2>
              <FormInput
                name="username"
                label="Username"
                errors={this.state.errors.username}
                rules={this.state.usernameRules}
                handleInputChange={this.handleInputChange}
              />
              <FormInput
                name="email"
                label="Email"
                errors={this.state.errors.email}
                rules={this.state.emailRules}
                handleInputChange={this.handleInputChange}
                warnings={[companyEmailWarning]}
              />
              <FormInput
                name="password"
                label="Password"
                placeholder="**********"
                type="password"
                errors={this.state.errors.password}
                rules={this.state.passwordRules}
                handleInputChange={this.handleInputChange}
              />
              <FormInput
                name="company"
                label="Company Name"
                placeholder="Acme Inc"
                errors={this.state.errors.company}
                rules={this.state.companyRules}
                handleInputChange={this.handleInputChange}
              />
              <div className="mb-6">
                <input
                  type="checkbox"
                  name="terms"
                  onChange={this.handleInputChange}
                />
                <label className="text-gray-700 text-sm ">
                  {" "}
                  I agree to the{" "}
                  <a target="_blank" href="/privacy-policy/">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a target="_blank" href="/terms/">
                    Terms and Conditions
                  </a>
                </label>
                {(this.state.errors.terms || []).map(err => (
                  <p className="text-red-500 text-xs italic" key={err}>
                    {err}
                  </p>
                ))}
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-primary hover:bg-primarydark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline block w-full"
                  type="button"
                  onClick={this.handleSubmit}
                >
                  Get started
                </button>
              </div>
              <div className="mt-4 text-center text-sm">
                <a
                  href="https://app.cargo-planner.com/"
                  className="text-primary hover:text-primarydark"
                  onClick={sendEvent("SignIn")}
                >
                  Go to Sign In instead
                </a>
              </div>
              {this.state.connectError && (
                <p className="text-red-500 text-sm italic mt-3">
                  {this.state.connectError}
                </p>
              )}
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RegisterPage

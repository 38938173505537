import React from "react"

class FormInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = { warnings: [] }
    }
    handleChange(e) {
        const errors = this.props.rules.map((r) => r(e.target.value)).filter(err => err !== true);
        this.props.handleInputChange(e, errors);
        let warnings = [];
        if (errors.length === 0 && this.props.warnings) {
            warnings = this.props.warnings.map((r) => r(e.target.value)).filter(err => err !== true);
        }
        this.setState({
            warnings
        });
    }

    render() {
        const errors = this.props.errors || [];
        const inputClasses = "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
        const errorClasses = "border-red-500"
        return (<div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={this.props.name}>
                {this.props.label}
            </label>
            <input
                className={inputClasses + (errors.length ? errorClasses : '')}
                id={this.props.name}
                name={this.props.name}
                type={this.props.type || "text"}
                placeholder={this.props.placeholder || this.props.label}
                onChange={this.handleChange} />
            {errors.map(err =>
                <p className="text-red-500 text-xs italic mt-2" key={err}>{err}</p>
            )}
            {this.state.warnings.map(warn =>
                <p className="text-yellow-500 text-xs italic mt-2" key={warn}>{warn}</p>
            )}
        </div>)

    }
}
export default FormInput;